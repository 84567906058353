@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600&display=swap");

.App {
  text-align: center;
  background-color: black;
  min-height: 150vh;
  @media screen and (-webkit-min-device-pixel-ratio: 1),
    and (min-device-width: 1200px), {
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
  }
}
.App-header {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}
